<template>
  <tr v-if="!!partner">
    <td>{{ partner.name }}</td>
    <td>
      <modal-btn :target="`#${showImage}`" class="btn-sm btn-info">عرض</modal-btn>
    </td>
    <td>
      <modal-btn btn-class="btn-sm btn-primary mr-1" :target="`#${editId}`">تعديل</modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${deleteModalId}`">حذف</modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="showImage"
        title="عرض الصورة"
        confirm-btn="hide"
        cancel-btn="btn-info"
        cancel-text="تم">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 d-flex justify-content-center">
              <img class="shadow" v-if="!!image" :src="image" :alt="partner.name">
            </div>
          </div>
        </div>
      </confirm-model>
      <confirm-model
        :target-id="editId"
        title="تعديل شريك"
        confirm-text="تعديل"
        @confirmed="clickById(editSubmitId)">
        <form @submit.prevent="update">
          <div class="form-group">
            <label>إسم الشريك</label>
            <form-input :id="`name${partner.id}`" name="name" :value="partner.name" placeholder="إسم الشريك"></form-input>
          </div>
          <div class="form-group">
            <form-image :id="`image${partner.id}`" name="image">تحميل الصورة</form-image>
          </div>
          <button hidden :id="editSubmitId"></button>
        </form>
      </confirm-model>
      <items-destroyer
        :target-id="deleteModalId"
        :url="deleteUrl"
        :row-id="rowId"
        @deleted="deleted">
        <span>هل أنت متأكد من حذف</span><span class="link">&nbsp;{{ partner.name }}&nbsp;</span> ؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import FormInput from "@/components/Forms/FormInput";
import FormImage from "@/components/Forms/FormImage";

export default {
  components: {FormImage, FormInput, ItemsDestroyer, ConfirmModel, ModalBtn},
  props: {partner: {required: true}},
  emits: ['deleted', 'updated'],
  computed: {
    showImage() {
      return !!this.partner ? `partner-logo-${this.partner.id}` : '';
    },
    publicPath() {
      return this.$store.getters.publicPath;
    },
    image() {
      return !!this.partner && this.partner.path ? `${this.publicPath}/storage/${this.partner.path}` : '';
    },
    editSubmitId() {
      return !!this.partner ? `partner-edit-submit-${this.partner.id}` : '';
    },
    editId() {
      return !!this.partner ? `partner-edit-${this.partner.id}` : '';
    },
    deleteModalId() {
      return !!this.partner ? `partner-delete-${this.partner.id}` : '';
    },
    deleteUrl() {
      return !!this.partner ? `partners/${this.partner.id}` : '';
    },
    rowId() {
      return !!this.partner ? `partners-row-${this.partner.id}` : '';
    }
  },
  methods: {
    deleted(id) {
      this.$emit('deleted', id);
    },
    async update(e) {
      if (!e || !this.partner)
        return;
      const formData = new FormData(e.target);
      formData.set('_method', 'PUT');
      const response = await http.send(`partners/${this.partner.id}`, formData, {headers: {"Content-Type": "multipart/form-data"}});
      if (http.responseAccepted(response)) {
        this.$emit('updated', response.data);
        this.hideModal(`#${this.editId}`);
      }
    }
  }
}
</script>

<style scoped>
img {
  max-height: 300px;
  max-width: 100%;
}
</style>
